<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">用户管理</h3>
    </div>
    <div class="shopuser">
      <div class="title">
        <p>
          <span>手机号:</span>
          <input
            v-model="form.mobile"
            placeholder="请输入手机查询"
            type="text"
          />
        </p>
        <p style="margin-top: 20px">
          <span>用户名:</span>
          <input
            v-model="form.username"
            placeholder="请输入用户名查询"
            type="text"
          />
        </p>
        <p style="margin-top: 20px">
          <el-radio v-model="form.status" :label="''">全部</el-radio>
          <el-radio v-model="form.status" :label="1">开启</el-radio>
          <el-radio v-model="form.status" :label="2">禁用</el-radio>
          <el-button size="mini" type="primary" @click="shaixuan">筛选</el-button>
        </p>
      </div>
      <el-table
        :data="list"
        stripe
        border
        :header-row-style="{ height: '40px' }"
        :row-style="{ height: '50px' }"
      >
        <el-table-column prop="id" label="用户ID" width="100">
        </el-table-column>
        <el-table-column prop="username" label="用户名" width="200">
        </el-table-column>
        <el-table-column label="所属门店" width="200">
          <div slot-scope="scope">
            <span style="color: #67c23a" v-if="scope.row.store_name != null">{{ scope.row.store_name}}</span>
            <span v-else>暂无</span>
          </div>
        </el-table-column>
        <el-table-column prop="mobile" label="电话" width="120">
        </el-table-column>
        <el-table-column label="用户类型" width="100">
          <div slot-scope="scope">
            <span v-if="scope.row.user_type === 1">普通用户</span>
            <span v-else>会员用户</span>
          </div>
        </el-table-column>
        <el-table-column label="用户角色" width="120">
          <div slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.role_name === null">暂无</el-tag>
            <el-tag type="warning" size="mini" v-else>{{scope.row.role_name}}</el-tag>
            <span class="set shou" @click="setRole(scope.row)">调整</span>
          </div>
        </el-table-column>
        <el-table-column label="头像" width="55">
          <div class="imgbox" slot-scope="scope">
            <img :src="scope.row.avatar" alt="" />
          </div>
        </el-table-column>
        <el-table-column label="注册时间" width="180">
          <div slot-scope="scope">
            {{ scope.row.createtime }}
          </div>
        </el-table-column>
        <el-table-column label="消费金额">
          <div slot-scope="scope">
            <span style="color: red">{{ scope.row.total_price }}</span>
          </div>
        </el-table-column>
        <el-table-column label="状态" width="130">
          <div slot-scope="scope">
            <el-tag v-if="scope.row.status == 1" type="success" effect="dark">可用</el-tag>
            <el-tag v-else type="info" effect="dark">禁用</el-tag>
            <span
              class="setstatus shou"
              @click="setStatus(scope.row.id, scope.row.status)"
              >修改</span>
          </div>
        </el-table-column>
        <el-table-column label="操作" width="250">
          <div class="caozuo" slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              round
              disabled
              @click="del(scope.row.id)"
              >删除</el-button>
            <el-button
              type="primary"
              round
              size="mini"
              @click="upvipFn(scope.row)"
              v-if="scope.row.user_type == 1"
              >升级会员</el-button>
              <el-button
              type="danger"
              round
              size="mini"
              @click="bindVipStore(scope.row)"
              v-if="scope.row.user_type == 2"
              >换绑门店</el-button>
              <el-button
              type="warning"
              round
              size="mini"
              @click="resetUser(scope.row.id)"
              >重置用户</el-button>
          </div>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 20px"
        @current-change="crtChg"
        :current-page="form.page"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>

      <el-dialog
        :title="'修改 ' + admin.username + '  的角色'"
        :visible.sync="setroleShow"
        width="24%"
      >
        <div>
          <p style="margin-bottom: 30px">
            当前角色:
            <span style="color: #111">{{
              admin.role_name == null ? "暂无角色" : admin.role_name
            }}</span>
          </p>
          <el-select v-model="role_id" placeholder="请选择">
            <el-option
              v-for="item in role_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="setroleShow = false">取 消</el-button>
          <el-button type="primary" @click="setroleSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 选择门店升级会员 -->
    <el-dialog title="升级会员信息" :visible.sync="upvipdialog" width="40%">
      <div class="shopVip">
        <div class="vipUser">
          <p>
            用户: <span style="color: red;">{{ username5 }}</span>
          </p>
          <p>
            门店: <span style="color: red;">{{ storeName5 }}</span>
          </p>
        </div>
        <div class="type">
          <p>
            <span>门店名称:</span>
            <input placeholder="请输入门店名称" v-model="name" type="text" />
          </p>
          <p>
            <span>手机号:</span>
            <input placeholder="请输入门店联系方式" v-model="phone" type="text" /><el-button
              size="mini"
              type="primary"
			        @click="shaixuans"
              >筛选</el-button>
          </p>
        </div>
        <el-table :data="shoplist" height="200" border style="width: 100%">
          <el-table-column prop="name" label="门店名称" width="180">
          </el-table-column>
          <el-table-column prop="mobile" label="联系方式" width="180">
          </el-table-column>
          <el-table-column prop="address" label="地址"></el-table-column>
          <el-table-column label="操作">
            <el-button
              slot-scope="scope"
              size="mini"
              round
              @click="selectShop(scope.row)"
              >选择</el-button>
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top: 20px"
          @current-change="ShopcrtChg"
          :current-page="shopForm.page"
          layout="prev, pager, next, jumper"
          :total="ShopTotal"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="upvipdialog = false">取 消</el-button>
        <el-button type="primary" @click="UpgradeVip">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 会员换绑门店 -->
    <el-dialog title="绑定门店会员信息" :visible.sync="bindStoreDialog" width="40%">
      <div class="shopVip">
        <div class="vipUser">
          <p>
            用户: <span style="color: red;">{{ username5 }}</span>
          </p>
          <p>
            门店: <span style="color: red;">{{ storeName5 }}</span>
          </p>
        </div>
        <div class="type">
          <p>
            <span>门店名称:</span>
            <input v-model="name" placeholder="请输入门店名称" type="text" />
          </p>
          <p>
            <span>手机号:</span>
            <input v-model="phone" placeholder="请输入门店联系方式" type="text" /><el-button
              size="mini"
              type="primary"
			  @click="shaixuans"
              >筛选</el-button>
          </p>
        </div>
        <el-table :data="shoplist" height="200" border style="width: 100%">
          <el-table-column prop="name" label="门店名称" width="180">
          </el-table-column>
          <el-table-column prop="phone" label="联系方式" width="180">
          </el-table-column>
          <el-table-column prop="address" label="地址"></el-table-column>
          <el-table-column label="操作">
            <el-button
              slot-scope="scope"
              size="mini"
              round
              @click="selectShop(scope.row)"
              >选择</el-button>
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top: 20px"
          @current-change="ShopcrtChg"
          :current-page="shopForm.page"
          layout="prev, pager, next, jumper"
          :total="ShopTotal"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="bindStoreDialog = false">取 消</el-button>
        <el-button type="primary" @click="vipBindStore">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  getshopuser,
  deluser,
  setUserStatus,
  postUserReset,
  setAdminRole,
  rolelist,
  getshoplist,
  upVIP,
  bindVipStore
} from "@/api/api.js";
// import { showLoading, hideLoading } from "@/utils/loading.js";
// import UpVip from "./components/upvip.vue";
export default {
  props: ["id"],
  components: {},
  name: "",
  data() {
    return {
      // 选择用户升级会员
      upvipdialog: false,
      bindStoreDialog: false,
      username5: "",
      storeName5: "",
      phone:'',
      name:'',
      shoplist: [],
      shopVIP: {
        user_id: "",
        store_id: "",
      },
      shopForm: {
        name: "",
        phone: "",
        limit: 10,
        page: 1
      },
      ShopTotal: 10,
      form: {
        username: "",
        status: "",
        mobile: "",
        page: 1,
        limit: 10,
      },
      list: [],
      total: 1,
      admin: {},
      setroleShow: false,
      role_list: [],
      role_id: null,
      shopRole_id: null,
    };
  },
  watch: {
    // 升级会员
    upvipdialog: function (newData, oldData) {
      if (newData == true) {
        if (this.shoplist.length == 0) {
          this.getstorelist();
        }
      }
    },
    // 换绑门店
    bindStoreDialog: function (newData, oldData) {
      if (newData == true) {
        if (this.shoplist.length == 0) {
          this.getstorelist();
        }
      }
    },
  },
  methods: {
    ShopcrtChg(e) {
      console.log(e);
      this.shopForm.page = e;
      this.getstorelist()
    },

    // 选择门店升级会员
    upvipFn(item) {
      this.shopVIP.user_id = item.id;
      this.username5 = item.username;
      this.upvipdialog = true;
    },

    bindVipStore(item){
      this.shopVIP.user_id = item.id;
      this.username5 = item.username;
      this.bindStoreDialog = true;
    },

    selectShop(item) {
      this.storeName5 = item.name;
      this.shopVIP.store_id = item.id;
    },

    // 升级会员
    async UpgradeVip() {
      console.log(this.shopVIP);
      if (this.shopVIP.user_id == "" || this.shopVIP.store_id == "") {
        return this.$message.error("请选择门店");
      }
      const { data } = await upVIP(this.shopVIP);
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success(data.data);
      this.upvipdialog = false;
      this.getlist();
    },

    // 换绑门店
    async vipBindStore() {
      console.log(this.shopVIP);
      if (this.shopVIP.user_id == "" || this.shopVIP.store_id == "") {
        return this.$message.error("请选择门店");
      }
      const { data } = await bindVipStore(this.shopVIP);
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success(data.data);
      this.bindStoreDialog = false;
      this.getlist();
    },

    async setroleSubmit() {
      const { data } = await setAdminRole({
        role_id: this.role_id,
        id: this.admin.id,
      });
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success(data.data);
      this.setroleShow = false;
      this.getlist();
    },

    setRole(item) {
      this.admin = item;
      this.setroleShow = true;
    },
    // 重置用户
    resetUser(id){
      this.$confirm('此操作为重置当前用户信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
          }).then(async() => {
            const { data } = await postUserReset({ user_id: id});
            if (data.code != 200) return this.$message.error(data.data);
             this.$message({
              type: "success",
              message: "操作成功!",
            });
          }).catch(() => {
              this.$message({
                  type: 'info',
                  message: '已取消'
              });          
      });
    },
    async setStatus(id, status) {
      this.$confirm("是否真的修改用户状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let sts = status == 1 ? 2 : 1;
          const { data } = await setUserStatus({ user_id: id, status: sts });
          if (data.code != 200) return this.$message.error(data.data);
          this.list.forEach((item) => {
            if (item.id == id) {
              let index = this.list.indexOf(item);
              this.list[index].status = sts;
            }
          });
          this.$message({
            type: "success",
            message: "修改成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },

    async del(id) {
      // const {data} = await deluser({id})
      console.log(id);
    },

    crtChg(e) {
      console.log(e);
      this.form.page = e;
      this.getlist();
    },

    shaixuan() {
      this.form.page = 1;
      this.form.limit = 10;
      this.getlist();
    },

    async getlist() {
      //   this.form.store_id = parseInt(this.id);
      const { data } = await getshopuser(this.form);
      this.total = data.data.total;
      this.list = data.data.data;
    },

    async getRolelist() {
      const { data } = await rolelist();
      this.role_list = data.data.data;
      this.role_list.unshift({ name: "普通用户", id: 0 });
      console.log(this.role_list);
    },

    async getstorelist() {
      const { data } = await getshoplist(this.shopForm);
      this.shoplist = data.data.data;
      this.ShopTotal = data.data.total
    },
	shaixuans(){
		this.shopForm.name=this.name
		this.shopForm.phone=this.phone
		this.getstorelist()
	},
  },
  created() {
    this.getlist();
    this.getRolelist();
  },
};
</script>

<style scoped lang='less'>
.shopuser {
  .title {
    font-size: 13px;
    background-color: #eee;
    padding: 10px;
    padding-top: 20px;
    margin: 5px 0 20px 0;
    p {
      input {
        height: 30px;
        margin-left: 5px;
        font-size: 12px;
      }
    }
  }
  .imgbox {
    width: 30px;
    img {
      width: 100%;
    }
  }
}
.setstatus {
  margin-left: 10px;
  border-bottom: 1px solid #888;
  display: inline-block;
  &:hover {
    color: #1aad19;
    border-bottom: 1px solid #1aad19;
  }
}
/deep/.el-table-column {
  padding: 0;
}
/deep/.set {
  margin-left: 10px;
  color: #777;
  font-size: 12px;
  padding-bottom: 2px;
  border-bottom: 1px solid #777;
  &:hover {
    color: #111;
    border-bottom: 1px solid #111;
  }
}
/deep/ .el-dialog__title {
  font-size: 16px !important;
}
.shopVip {
  .type {
    p {
      .el-button {
        margin-left: 20px;
      }
      display: flex;
      margin: 10px;
      align-items: center;
      span {
        display: inline-block;
        width: 60px;
        margin-right: 5px;
      }
      input {
        height: 20px;
      }
    }
  }
  .vipUser {
    // background-color: #f1f2f3;
    padding: 10px 0;
    display: flex;
    margin-bottom: 20px;
    p {
      font-size: 12px;
      font-weight: 600;
      margin-right: 10px;
      // color: #36d6b6;
      &:nth-child(1) {
        padding: 0 6px;
      }
    }
  }
}
/deep/.el-dialog__body {
  padding: 10px;
}
</style>